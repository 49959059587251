@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  box-sizing: border-box;
  background-color: $primary;
  padding: 1em;
  margin: 0.5em;
  border-radius: $borderRadius;
  box-shadow: 0.125em 0.125em 0.5em 0 rgba(black, 0.25);

  min-width: 8em;
  font-size: 1em;
  cursor: pointer;
  outline: 0;
  border: 0;

  &:active,
  &.active {
    box-shadow: inset 0.125em 0.125em 0.5em 0 rgba(black, 0.25);
    outline: 0;
  }

  &:focus {
    outline: 0;
  }

  &.secondary {
    border: 1px solid $primary;
    background-color: transparent;
    box-shadow: none;
  }
}

.label {
  font-weight: bold;
  color: $text-inverse;

  &.secondary {
    color: $primary;
  }
}
