@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  font-weight: bold;
  color: $primary;
  text-transform: uppercase;
}
