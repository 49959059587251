@import '../../theme/sizes';

.container {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  margin-bottom: 1em;
  overflow-y: scroll;
  min-height: 20em;
}
