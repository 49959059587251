@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: $screenSize;
  min-height: $screenSize;
}

.buttons {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
