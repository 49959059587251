@import 'theme/colors';

.container {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 2em;

  box-sizing: border-box;
}
