@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  flex: 1;
  color: $text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background-color: $primary-dimmed;
  border-radius: $borderRadius;
}

.message {
  font-weight: bold;
  color: $failed;
  margin-bottom: 1em;
}
