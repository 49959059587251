@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.125em 0;
  line-height: 1;
}

.icon {
  width: 1.5em;
  text-align: left;
}

.bar {
  flex: 1;
  position: relative;
  width: 100%;
  height: 1em;
}

.progress {
  position: relative;
  height: 1em;
  width: 100%;
  transform-origin: left;
  transform: scale(0.5);

  &.animated {
    transition: transform 1s ease-out;
  }
}

.fill {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: $primary;

  &.energy {
    background-color: $success;
  }
  &.timer {
    background-color: $primary;
  }

  &.animated {
    transition: opacity 2s ease-out;

    &.triggered {
      animation: blink 2s ease-out;
    }
  }

  &.pointsAnimation {
    background-color: $success;
    transform-origin: left;
    animation: points 2s ease-out;
  }

  &.red {
    background-color: $failed;
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }
}

@keyframes points {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}
