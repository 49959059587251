@import '../../theme/colors';

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1em;
  overflow-y: scroll;
  scrollbar-gutter: stable both-edges;
}

.item {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 0.25em;
  cursor: pointer;
}
