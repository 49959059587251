@import '../../theme/colors';
@import '../../theme/sizes';

.image {
  width: 10em;
  height: 10em;
  border-radius: $borderRadius;
  display: block;
  object-fit: cover;

  &.answers {
    width: 8em;
    height: 8em;
  }
  &.players {
    width: 3em;
    height: 3em;
  }
}
