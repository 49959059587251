@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: $screenSize;
  min-height: $screenSize;
}

.description {
  font-weight: bold;
  white-space: nowrap;
}

.score {
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.textScore {
  font-size: 1.5em;
  font-weight: bold;
  color: $success;
  margin-left: 0.25em;
}

.comment {
  margin-bottom: 1em;
}

.buttons {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
