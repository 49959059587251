@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button.revealed {
  &.clicked {
    background-color: $failed;
  }

  &.timeUp {
    background-color: $failed;
  }

  &.correct {
    background-color: $success;
  }
}
