@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background-color: $primary-dimmed;
  border-radius: $borderRadius;
}

.revealed {
  &.SUCCESS {
    background-color: $success;
    color: $text-inverse;
  }

  &.FAILED {
    background-color: $failed;
    color: $text-inverse;
  }

  &.clicked {
    opacity: 1;
  }
}
