@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.row {
  flex-shrink: 0;
  height: 4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  & + & {
    border-bottom: 1px dashed $primary-dimmed;
  }

  &.leader {
    background-color: $primary-dimmed;
    border-radius: $borderRadius;
  }

  &.selected {
    background-color: $success;
    border-radius: $borderRadius;
    position: sticky;
    top: -1px;
    bottom: -1px;
    border: 1px solid $text-inverse;
    border-width: 1px 0;
  }
}

.cell {
  margin: 0;
  padding: 0.5em;
  overflow: hidden;

  &.index {
    text-align: right;
    width: 3em;
  }

  &.name {
    text-align: left;
    flex: 1;
  }

  &.score {
    text-align: right;
    padding-right: 2em;
  }

  &.victories {
    text-align: right;
  }
}

.icon {
  &.victories {
    margin-left: .25em;
  }
}

.text {
  font-size: 1em;
  line-height: 1.5;
  font-weight: bold;

  &.score {
    color: $primary;
  }

  &.victories {
    color: $primary;
  }

  &.name.leader {
    font-size: 1.5em;
  }

  &.index {
    font-size: 1em;
    color: $primary;
  }

  &.selected {
    color: $text-inverse;
  }
}
