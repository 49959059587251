@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: $screenSize;
  height: $screenSize;
  border-radius: $borderRadius;
}

.title {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
  color: $primary;
  text-transform: uppercase;
}

.answers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.horizontal {
  flex-direction: row;
}
