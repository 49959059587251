@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  font-weight: bold;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.666em;

  &.small {
    display: none
  }
}

.logo {
  height: 3em;
  margin: -0.5em;
}

.label {
  margin-top: 1em;
  align-self: stretch;
  font-size: 1em;
  font-weight: bold;
  color: $primary;
  text-transform: uppercase;
  border-bottom: 1px solid $primary-dimmed;
  padding-bottom: .5em;
}
