@import 'colors';

html {
  line-height: 1;
}

body {
  font-size: inherit;
  line-height: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  line-height: 1;
  margin: 0 0 1em 0;
  padding: 0;
  font-weight: normal;
}

p {
  font-size: 10em;
}

h1 {
  font-size: 3em;
  text-align: center;
}
h2 {
  font-size: 2em;
  text-align: center;
}
h3 {
  font-size: 2em;
  text-align: center;
}
h4 {
  font-size: 1em;
  color: $primary;
  font-weight: bold;
  margin: 0;
}
