@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 1em;
  min-width: 15em;
}

.label {
  &.questions {
    font-size: 2em;
    font-weight: bold;
  }
}


