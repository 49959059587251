@import '../../theme/colors';
@import '../../theme/sizes';

.container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $primary-dimmed;
  border-radius: $borderRadius;
}

.label {
  padding: 1em;
}

.bars {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.score {
  font-size: 1.5em;
  font-weight: bold;
  color: $primary;

  &.animated {
    color: $success;
  }
}
